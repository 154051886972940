/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

/* -------------------------------------------------------------------------- */
/*                                 ANCHOR VARS                                */
/* -------------------------------------------------------------------------- */

$primary-color: #F67B06;
$secondary-color: #D8D9DD;
$third-color: #046640;
$fourth-color: #444242;
$fifth-color: #044C2E;

$font-weights: 100, 200, 300, 400, 500, 600, 700, 800, 900;

@font-face {
   font-family: Aileron-Regular;
   src: url(assets/font/Aileron-Regular.otf) format("opentype");
}

@font-face {
   font-family: Aileron-Bold;
   src: url(assets/font/Aileron-Bold.otf) format("opentype");
}

body {
   font-family: 'Aileron-Regular', 'arial', sans-serif;
   overflow-x: hidden;
}

b {
   font-family: 'Aileron-Bold', 'arial', sans-serif;
}

a {
   color: $third-color;
}

a:hover {
   color: $fifth-color;
}

/* -------------------------------------------------------------------------- */
/*                             ANCHOR BACKGROUNDS                             */
/* -------------------------------------------------------------------------- */

.bg-primary {
   background-color: $primary-color !important;
}

.bg-secondary {
   background-color: $secondary-color !important;
}

.bg-third {
   background-color: $third-color !important;
}

.bg-fifth {
   background-color: $fifth-color !important;
}

.bg-center {
   background-position: center;
   background-size: cover;
   background-repeat: no-repeat;
}

.bg-transparent-dark {
   background-color: rgba(0, 0, 0, 0.4) !important
}

.bg-green-lighten-4 {
   background-color: #c8e6c9 !important;
}

/* -------------------------------------------------------------------------- */
/*                                 ANCHOR TEXT                                */
/* -------------------------------------------------------------------------- */

.text-third {
   color: $third-color;
}

.text-fourth {
   color: $fourth-color;
}

.text-fifth {
   color: $fifth-color;
}

.text-justify {
   text-align: justify;
}

.text-decoration-hover:hover {
   text-decoration: underline !important;
}

.nav-link:hover {
   background-color: $primary-color !important;
   color: white !important;
}

.fw-bold {
   font-family: 'Aileron-Bold', 'arial', sans-serif;
}

.fw-light {
   font-family: 'Aileron-Regular', 'arial', sans-serif;
}

@each $font-weight in $font-weights {
   .fw-#{$font-weight} {
      font-weight: $font-weight;
   }
}

.btn-primary {
   background-color: $primary-color;
   color: white !important;
}

.main-footer {
   background-image: url("assets/media/img/FONDO-CONTACTO.jpg");
   background-size: cover;
   width: 100%;
   background-position: center;
   background-repeat: no-repeat;
}

.main-text-footer {
   color: #046640 !important;
   width: auto;
   height: auto;
   font-size: 250%;
   font-family: 'Aileron-Regular', 'arial', sans-serif;
}


.sub-text-footer-green {
   color: #046640 !important;
   width: auto;
   height: auto;
   font-size: 110%;
   font-family: 'Aileron-Bold', 'arial', sans-serif;
}


.sub-text-footer {
   color: #444242 !important;
   width: auto;
   height: auto;
   font-size: 110%;
   font-family: 'Aileron-Regular', 'arial', sans-serif;
}

.btn-form-footer {
   color: #444242 !important;
   background-color: #D8D9DD;
   width: auto;
   height: auto;
   font-family: 'Aileron-Bold', 'arial', sans-serif;

}

.icons-top-banner {
   width: 75%;
   height: 75%;
}

.icons-footer {
   width: 10%;
   height: 10%;
}

.icons-notice
{
   width: 120%;
   height: 100%;

}

.btn-sofipo {
   color: white !important;
   background-color: $primary-color;
   width: auto;
   height: auto;
   border: 0px;
   padding: 15px;
   font-family: 'Aileron-Bold', 'arial', sans-serif;
}

.top-banner-btn {
   position: absolute;
   bottom: 110px;
   left: 250px;
}

.img-carrousel {
   width: auto;
   height: 9%;
}

.logo-bco
{
   width: 25%;

}

.mini-logo-bco
{
   width: 160%;
}

.text-top-banner
{
   color: white !important;
   font-size: 75%;
   font-family: 'Aileron-Regular', 'arial', sans-serif;
}

.container-notice
{
   color:#044C2E!important;
   background-color: #D8D9DD;
}